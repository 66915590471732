import React from "react";
import "./List.module.css";
import { url } from "../../pages/HomePage";
import { useState } from "react";
import PropertyTypeHome from "../propertytype/PropertyTypeHome";
import classes from "./ReadOnlyRow.module.css";

function ReadOnlyRow({
  estate,
  estate: {
    id,
    name,
    address,
    city,
    constructed,
    postNumber,
    total_maintenanceDebtPerSqM,
    residentialArea,
    propertyType,
    apartmentArea,
    user,
    pubD,
    checked,
  },
  captureEdit,
  publicData,
}) {
  const [Data, setData] = useState("");
  const openInSamePage = (url) => {
    window.open(url, "_self", "noopener,noreferrer");
  };
  const oldTotal_maintenanceData = total_maintenanceDebtPerSqM + "";
  let newTotal_maintenanceData = oldTotal_maintenanceData
    .replace("[", "")
    .replace("]", "");
  newTotal_maintenanceData = newTotal_maintenanceData.split(",");
  newTotal_maintenanceData = newTotal_maintenanceData.map(Number);

  let oldAddress = address + "";
  oldAddress = oldAddress.split(" / ");
  let newAddress = `[${oldAddress}]`
    .replaceAll("[", "")
    .replaceAll("]", "")
    .replaceAll(",", ", ");

  let oldCity = city + "";
  oldCity = oldCity.toLocaleLowerCase();
  let newCity = oldCity.charAt(0).toUpperCase() + oldCity.slice(1);

  let oldPubD = pubD + "";
  oldPubD = oldPubD.split("T")[0].split("-");
  oldPubD = oldPubD[2] + "." + oldPubD[1] + "." + oldPubD[0];
  let newPubD = oldPubD;

  async function deleteEstate(newId) {
    if (
      publicData.organization === "testgroup" ||
      publicData.organization === "Kilpailija"
    ) {
      await fetch(url + "api/properties/" + newId, {
        credentials: "include",
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setData(data);
        });
      if (Data.user === publicData.username) {
        fetch(url + "api/properties/" + newId, {
          method: "DELETE",
          credentials: "include",
        }).then(() => {
          window.location.reload();
        });
      } else {
        window.confirm(
          "Et voi poistaa tätä kiinteistöä, koska et ole luonut sitä"
        );
      }
    } else {
      fetch(url + "api/properties/" + newId, {
        method: "DELETE",
        credentials: "include",
      }).then(() => {
        window.location.reload();
      });
    }
  }

  const options = ["Muokkaa", "Poista"];

  const [isOpen, setIsOpen] = useState(false);

  const toggleOn = () => setIsOpen(true);
  const toggleOff = () => setIsOpen(false);

  const onOptionClicked = (value) => () => {
    setIsOpen(false);
    switch (value) {
      case "Muokkaa":
        if (publicData.privilege === 0 || publicData.privilege === 1 || estate.checked === false) {
          captureEdit(estate);
          openInSamePage("/hallitse/" + id);
        } else {
          window.alert(
            "Kiinteistö on varmistettu. Sitä pääsee muokkaamaan vain adminit"
          );
        }
        break;
      case "Poista":
        window.confirm(`Haluatko poistaa kiinteistön nimeltä: ${name}?`) &&
          deleteEstate(id);
        break;
      default:
        break;
    }
  };

  function getUser(i) {
    var user = i + "";
    var newUser = user.split("@")[0];
    return newUser;
  }
  function isPrivilegeTrue() {
    if (publicData.privilege === 0) {
      return true;
    } else if (publicData.privilege === 1) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <tr
      key={id}
      className={user === publicData.username ? classes.changeColor : null}
    >
      <td>
        <a
          href={"/kiinteisto/" + id}
          className={checked ? classes.li : classes.liRed}
        >
          {name}
        </a>
      </td>
      <td>{newAddress}</td>
      <td>{postNumber}</td>
      <td>{newCity}</td>
      <PropertyTypeHome propertyType={propertyType} />
      <td>{constructed}</td>
      <td>{Math.round(newTotal_maintenanceData[0])}</td>
      <td>{residentialArea}</td>
      <td>{apartmentArea}</td>
      {isPrivilegeTrue() ? <td>{getUser(user)}</td> : null}
      {isPrivilegeTrue() ? <td>{newPubD}</td> : null}
      <td
        style={{ fontSize: "1.3rem", padding: "0" }}
        onMouseEnter={toggleOn}
        onMouseLeave={toggleOff}
      >
        <p
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            padding: "8px",
          }}
        >
          ...
        </p>
        {isOpen && (
          <div className={classes.dropdown}>
            {options.map((option) => (
              <p
                className={classes.link}
                onClick={onOptionClicked(option)}
                key={Math.random()}
              >
                {option}
              </p>
            ))}
          </div>
        )}
      </td>
    </tr>
  );
}

export default ReadOnlyRow;
