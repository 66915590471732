import classes from "./Edit.module.css";
import returnArrow from "../../assets/returnArrow.png";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRef } from "react";
import { url } from "../../pages/HomePage";

function  EditPage(props) {
  const [userData, setUser] = useState([]);
  const [Data, setData] = useState([]);
  const [normalValue, setNormalValue] = useState();
  const [showYardCase, setShowYardCase] = useState(false);
  const params = useParams();
  /* let { name, address, city, constructed} = editForm */

  const errorText = "Suunniteltu korjausvuosi ei voi olla menneisyydessä";
  const errorText2 =
    "Rakennettu / remontoitu viimeksi ei voi olla tulevaisuudessa";
  const errorText3 =
    "Rakennusvuosi täytyy olla nykyvuoden ja vuoden 1850 väliltä";
  const errorColor = "#fcb6b6";

  const addressNumber = useRef();

  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [addressValue, setAddressValue] = useState("");

  const [dynamicSewer_area, setDynamicSewer_area] = useState("");
  const [dynamicPlumbing_area, setDynamicPlumbing_area] = useState("");
  const [dynamicHeating_area, setDynamicHeating_area] = useState("");
  const [dynamicElec_area, setDynamicElec_area] = useState("");
  const [dynamicVent_area, setDynamicVent_area] = useState("");
  const [dynamicFront_area, setDynamicFront_area] = useState("");
  const [dynamicWindows_and_balconies_area, setDynamicWindows_and_balconies_area] = useState("");
  const [dynamicBathrooms, setDynamicBathrooms] = useState("");
  const [dynamicRoof_price, setDynamicRoof_price] = useState("");

  const [preResidentialArea, setPreResidentialArea] = useState("");
  const [preCommercialArea, setPreCommercialArea] = useState("");
  const [preFloors, setPreFloors] = useState("");

  const [resAreaVal, setResAreaVal] = useState("");
  const [comAreaVal, setComAreaVal] = useState("");

  //planned renovation values
  const [checkSewer, setCheckSewer] = useState("");
  const [sewerError, setSewerError] = useState(false);

  const [checkPlumbing, setCheckPlumbing] = useState("");
  const [plumbingError, setPlumbingError] = useState(false);

  const [checkBathroom, setCheckBathroom] = useState("");
  const [bathroomError, setBathroomError] = useState(false);

  const [checkHeating, setCheckHeating] = useState("");
  const [heatingError, setHeatingError] = useState(false);

  const [checkElec, setCheckElec] = useState("");
  const [elecError, setElecError] = useState(false);

  const [checkVent, setCheckVent] = useState("");
  const [ventError, setVentError] = useState(false);

  const [checkFront, setCheckFront] = useState("");
  const [frontError, setFrontError] = useState(false);

  const [checkWindows_and_balconies, setCheckWindows_and_balconies] = useState("");
  const [windows_and_balconiesError, setWindows_and_balconiesError] = useState(false);

  const [checkYard, setCheckYard] = useState("");
  const [yardError, setYardError] = useState(false);

  const [checkYardCover, setCheckYardCover] = useState("");
  const [yardCoverError, setYardCoverError] = useState(false);

  const [checkRoof, setCheckRoof] = useState("");
  const [roofError, setRoofError] = useState(false);

  const [checkElevator, setCheckElevator] = useState("");
  const [elevatorError, setElevatorError] = useState(false);

  //renovated values
  const [sewerRenovated, setSewerRenovated] = useState("");
  const [sewerRenovatedError, setSewerRenovatedError] = useState(false);

  const [plumbingRenovated, setPlumbingRenovated] = useState("");
  const [plumbingRenovatedError, setPlumbingRenovatedError] = useState(false);

  const [bathroomRenovated, setBathroomRenovated] = useState("");
  const [bathroomRenovatedError, setBathroomRenovatedError] = useState(false);

  const [heatingRenovated, setHeatingRenovated] = useState("");
  const [heatingRenovatedError, setHeatingRenovatedError] = useState(false);

  const [elecRenovated, setElecRenovated] = useState("");
  const [elecRenovatedError, setElecRenovatedError] = useState(false);

  const [ventRenovated, setVentRenovated] = useState("");
  const [ventRenovatedError, setVentRenovatedError] = useState(false);

  const [frontRenovated, setFrontRenovated] = useState("");
  const [frontRenovatedError, setFrontRenovatedError] = useState(false);

  const [windows_and_balconiesRenovated, setWindows_and_balconiesRenovated] = useState("");
  const [windows_and_balconiesRenovatedError, setWindows_and_balconiesRenovatedError] = useState(false);

  const [yardRenovated, setYardRenovated] = useState("");
  const [yardRenovatedError, setYardRenovatedError] = useState(false);

  const [yardCoverRenovated, setYardCoverRenovated] = useState("");
  const [yardCoverRenovatedError, setYardCoverRenovatedError] = useState(false);

  const [roofRenovated, setRoofRenovated] = useState("");
  const [roofRenovatedError, setRoofRenovatedError] = useState(false);

  const [elevatorRenovated, setElevatorRenovated] = useState("");
  const [elevatorRenovatedError, setElevatorRenovatedError] = useState(false);

  //constructed value
  const [constructed, setConstructed] = useState("");
  const [constructedError, setConstructedError] = useState(false);

  //checked
  const [isChecked, setIsChecked] = useState();

  //planned renovation timeouts
  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfSewerExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkSewer]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfPlumbingExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkPlumbing]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfBathroomExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkBathroom]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfHeatingExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkHeating]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfElecExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkElec]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfVentExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkVent]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfFrontExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkFront]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfWindows_and_balconiesExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkWindows_and_balconies]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfYardExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkYard]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfYardCoverExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkYardCover]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfRoofExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkRoof]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfElevatorExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkElevator]);

  //renovated timeouts
  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfSewerExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sewerRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfPlumbingExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plumbingRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfBathroomExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bathroomRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfHeatingExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heatingRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfElecExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elecRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfVentExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ventRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfFrontExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frontRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfWindows_and_balconiesExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windows_and_balconiesRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfYardExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yardRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfYardCoverExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yardCoverRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfRoofExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roofRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfElevatorExists2();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elevatorRenovated]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      checkIfConstructedExists();
    }, 1500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constructed]);

  useEffect(() => {
    fetch(url + "api/properties/" + params.id, {
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })

      .then((data) => {
        let oldAddress = data.address + "";
        oldAddress = oldAddress.split(" / ");
        setAddress1(oldAddress[0]);
        if (oldAddress[1] === undefined) {
          setAddress2("");
        } else {
          setAddress2(oldAddress[1]);
        }
        if (oldAddress[2] === undefined) {
          setAddress3("");
        } else {
          setAddress3(oldAddress[2]);
        }
        setAddressValue(oldAddress[0]);
        setData(data);
        setIsChecked(data.checked);
        setNormalValue(data.propertyType);
        setResAreaVal(data.residentialArea);
        setComAreaVal(data.commercialArea);
        setPreResidentialArea(data.residentialArea);
        setPreCommercialArea(data.commercialArea);
        setPreFloors(data.floors);
        setDynamicSewer_area(data.sewer_area);
        setDynamicPlumbing_area(data.plumbing_area);
        setDynamicHeating_area(data.heating_area);
        setDynamicElec_area(data.elec_area);
        setDynamicVent_area(data.vent_area);
        setDynamicFront_area(data.front_area);
        setDynamicWindows_and_balconies_area(data.windows_and_balconies_area)
        setDynamicBathrooms(data.bathrooms);
        if (data.roof_price === 0) {
          setDynamicRoof_price(
            Math.round(
              ((Number(data.residentialArea) + Number(data.commercialArea)) /
                Number(data.floors)) *
                1.2
            ) * props.defaults[16]
          );
        } else {
          setDynamicRoof_price(data.roof_price);
        }
        if (data.yard_cover_area !== 0) {
          setShowYardCase(true);
        }
      });
    fetch(url + "me", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUser(data);
      });
  }, [params.id, props.defaults]);
  /*   const handleDeleteClick = (estateId) => {
      const newEstates = [...estates];
  
      const index = estates.findIndex((estate)=> estate.id === estateId);
  
      newEstates.splice(index, 1)
  
      setData(newEstates);
    }; */

  const nameInputRef = useRef();
  const addressInputRef = useRef();
  const postNumberInputRef = useRef();
  const cityInputRef = useRef();
  const propertyTypeInputRef = useRef();
  const constructedInputRef = useRef();
  const floorsInputRef = useRef();
  const apartmentsInputRef = useRef();
  const residentialAreaInputRef = useRef();
  const commercialAreaInputRef = useRef();
  const apartmentAreaInputRef = useRef();
  /* const loanPeriodInputRef = useRef(); */
  const rateInputRef = useRef();

  const sewer_areaInputRef = useRef();
  const sewer_renovatedInputRef = useRef();
  const sewer_plannedRenovationInputRef = useRef();
  const sewer_rPriceInputRef = useRef();
  const sewer_rRPriceInputRef = useRef();

  const plumbing_areaInputRef = useRef();
  const plumbing_renovatedInputRef = useRef();
  const plumbing_plannedRenovationInputRef = useRef();
  const plumbing_rPriceInputRef = useRef();
  const plumbing_rRPriceInputRef = useRef();

  const bathroomsInputRef = useRef();
  const bathroom_renovatedInputRef = useRef();
  const bathroom_plannedRenovationInputRef = useRef();
  const bathroom_priceInputRef = useRef();
  const bathroom_rRPriceInputRef = useRef();

  const heating_areaInputRef = useRef();
  const heating_renovatedInputRef = useRef();
  const heating_plannedRenovationInputRef = useRef();
  const heating_rPriceInputRef = useRef();
  const heating_rRPriceInputRef = useRef();

  const elec_areaInputRef = useRef();
  const elec_renovatedInputRef = useRef();
  const elec_plannedRenovationInputRef = useRef();
  const elec_rPriceInputRef = useRef();
  const elec_rRPriceInputRef = useRef();

  const vent_areaInputRef = useRef();
  const vent_renovatedInputRef = useRef();
  const vent_plannedRenovationInputRef = useRef();
  const vent_rPriceInputRef = useRef();
  const vent_rRPriceInputRef = useRef();

  const front_areaInputRef = useRef();
  const front_renovatedInputRef = useRef();
  const front_plannedRenovationInputRef = useRef();
  const front_rPriceInputRef = useRef();
  const front_rRPriceInputRef = useRef();

  const windows_and_balconies_areaInputRef = useRef();
  const windows_and_balconies_renovatedInputRef = useRef();
  const windows_and_balconies_plannedRenovationInputRef = useRef();
  const windows_and_balconies_rPriceInputRef = useRef();
  const windows_and_balconies_rRPriceInputRef = useRef();

  /* const yard_areaInputRef = useRef(); */
  const yard_renovatedInputRef = useRef();
  const yard_plannedRenovationInputRef = useRef();
  const yard_priceInputRef = useRef();
  const yard_rRPriceInputRef = useRef();

  const yard_cover_areaInputRef = useRef();
  const yard_cover_renovatedInputRef = useRef();
  const yard_cover_plannedRenovationInputRef = useRef();
  const yard_cover_priceInputRef = useRef();
  const yard_cover_rRPriceInputRef = useRef();

  /* const roof_areaInputRef = useRef(); */
  const roof_renovatedInputRef = useRef();
  const roof_plannedRenovationInputRef = useRef();
  const roof_priceInputRef = useRef();
  const roof_rRPriceInputRef = useRef();

  const elevatorsInputRef = useRef();
  const elevators_renovatedInputRef = useRef();
  const elevators_plannedRenovationInputRef = useRef();
  const elevators_priceInputRef = useRef();
  const elevators_rRPriceInputRef = useRef();

  var newSewer_rPrice;
  if (Data.sewer_rPrice === 0) {
    newSewer_rPrice = props.defaults[17];
  } else {
    newSewer_rPrice = Data.sewer_rPrice;
  }

  var newPlumbing_rPrice;
  if (Data.plumbing_rPrice === 0) {
    newPlumbing_rPrice = props.defaults[15];
  } else {
    newPlumbing_rPrice = Data.plumbing_rPrice;
  }

  var newBathroom_price;
  if (Data.bathroom_price === 0) {
    newBathroom_price = props.defaults[10];
  } else {
    newBathroom_price = Data.bathroom_price;
  }

  var newHeating_rPrice;
  if (Data.heating_rPrice === 0) {
    newHeating_rPrice = props.defaults[14];
  } else {
    newHeating_rPrice = Data.heating_rPrice;
  }

  var newElec_rPrice;
  if (Data.elec_rPrice === 0) {
    newElec_rPrice = props.defaults[11];
  } else {
    newElec_rPrice = Data.elec_rPrice;
  }

  var newVent_rPrice;
  if (Data.vent_rPrice === 0) {
    newVent_rPrice = props.defaults[18];
  } else {
    newVent_rPrice = Data.vent_rPrice;
  }

  var newFront_rPrice;
  if (Data.front_rPrice === 0) {
    newFront_rPrice = props.defaults[13];
  } else {
    newFront_rPrice = Data.front_rPrice;
  }

  var newWindows_and_balconies_rPrice;
  if (Data.windows_and_balconies_rPrice === 0) {
    newWindows_and_balconies_rPrice = props.defaults[26];
  } else {
    newWindows_and_balconies_rPrice = Data.windows_and_balconies_rPrice;
  }

  var newYard_price;
  if (Data.yard_price === 0) {
    newYard_price = props.defaults[19];
  } else {
    newYard_price = Data.yard_price;
  }

  var newYard_cover_price;
  if (Data.yard_cover_price === 0) {
    newYard_cover_price = props.defaults[23];
  } else {
    newYard_cover_price = Data.yard_cover_price;
  }

  var newElevators_price;
  if (Data.elevators_price === 0) {
    newElevators_price = props.defaults[12];
  } else {
    newElevators_price = Data.elevators_price;
  }

  function checkIfEdited(i) {
    if (Number(i) === 0) {
      return "";
    } else {
      return i;
    }
  }

  function setAddress(i, value) {
    if (Number(i) === 1) {
      setAddress1(value);
    } else if (Number(i) === 2) {
      setAddress2(value);
    } else if (Number(i) === 3) {
      setAddress3(value);
    }
  }

  function returnAddress(index) {
    if (Number(index) === 1) {
      return address1;
    } else if (Number(index) === 2) {
      return address2;
    } else if (Number(index) === 3) {
      return address3;
    }
  }
  function setRef() {
    addressInputRef.current.value = "";
  }

  function avoidSpaces(name, i) {
    if (name === "Huoneistojenala") {
      var key = i + "";
      setResAreaVal(key.replace(" ", ""));
    } else if (name === "commercialArea") {
      key = i + "";
      setComAreaVal(key.replace(" ", ""));
    }
  }

  function assignValues(com, res) {
    var oldCom = com + "";
    var newCom = oldCom.split(",").join(".");
    var oldRes = res + "";
    var newRes = oldRes.split(",").join(".");
    setPreCommercialArea(newCom);
    setPreResidentialArea(newRes);
    setDynamicSewer_area(Number(newRes) + Number(newCom));
    setDynamicPlumbing_area(Number(newRes) + Number(newCom));
    setDynamicHeating_area(Number(newRes) + Number(newCom));
    setDynamicElec_area(Number(newRes) + Number(newCom));
    setDynamicVent_area(Number(newRes) + Number(newCom));
    setDynamicFront_area(Number(newRes) + Number(newCom));
    setDynamicWindows_and_balconies_area(Number(newRes) + Number(newCom))
    setDynamicRoof_price(
      Math.round(
        ((Number(newRes) + Number(newCom)) / Number(preFloors)) * 1.2
      ) * props.defaults[16]
    );
  }

  function reRenderRoof(floors) {
    setDynamicRoof_price(
      Math.round(
        ((Number(preResidentialArea) + Number(preCommercialArea)) /
          Number(floors)) *
          1.2
      ) * props.defaults[16]
    );
  }

  //check if exist functions
  function checkIfSewerExists() {
    if (checkSewer < new Date().getFullYear() && checkSewer !== "") {
      setSewerError(true);
    } else {
      setSewerError(false);
    }
  }
  function checkIfSewerExists2() {
    if (sewerRenovated > new Date().getFullYear() && sewerRenovated !== "") {
      setSewerRenovatedError(true);
    } else {
      setSewerRenovatedError(false);
    }
  }

  function checkIfPlumbingExists() {
    if (checkPlumbing < new Date().getFullYear() && checkPlumbing !== "") {
      setPlumbingError(true);
    } else {
      setPlumbingError(false);
    }
  }
  function checkIfPlumbingExists2() {
    if (
      plumbingRenovated > new Date().getFullYear() &&
      plumbingRenovated !== ""
    ) {
      setPlumbingRenovatedError(true);
    } else {
      setPlumbingRenovatedError(false);
    }
  }

  function checkIfBathroomExists() {
    if (checkBathroom < new Date().getFullYear() && checkBathroom !== "") {
      setBathroomError(true);
    } else {
      setBathroomError(false);
    }
  }
  function checkIfBathroomExists2() {
    if (
      bathroomRenovated > new Date().getFullYear() &&
      bathroomRenovated !== ""
    ) {
      setBathroomRenovatedError(true);
    } else {
      setBathroomRenovatedError(false);
    }
  }

  function checkIfHeatingExists() {
    if (checkHeating < new Date().getFullYear() && checkHeating !== "") {
      setHeatingError(true);
    } else {
      setHeatingError(false);
    }
  }
  function checkIfHeatingExists2() {
    if (
      heatingRenovated > new Date().getFullYear() &&
      heatingRenovated !== ""
    ) {
      setHeatingRenovatedError(true);
    } else {
      setHeatingRenovatedError(false);
    }
  }

  function checkIfElecExists() {
    if (checkElec < new Date().getFullYear() && checkElec !== "") {
      setElecError(true);
    } else {
      setElecError(false);
    }
  }
  function checkIfElecExists2() {
    if (elecRenovated > new Date().getFullYear() && elecRenovated !== "") {
      setElecRenovatedError(true);
    } else {
      setElecRenovatedError(false);
    }
  }

  function checkIfVentExists() {
    if (checkVent < new Date().getFullYear() && checkVent !== "") {
      setVentError(true);
    } else {
      setVentError(false);
    }
  }
  function checkIfVentExists2() {
    if (ventRenovated > new Date().getFullYear() && ventRenovated !== "") {
      setVentRenovatedError(true);
    } else {
      setVentRenovatedError(false);
    }
  }

  function checkIfFrontExists() {
    if (checkFront < new Date().getFullYear() && checkFront !== "") {
      setFrontError(true);
    } else {
      setFrontError(false);
    }
  }
  function checkIfFrontExists2() {
    if (frontRenovated > new Date().getFullYear() && frontRenovated !== "") {
      setFrontRenovatedError(true);
    } else {
      setFrontRenovatedError(false);
    }
  }

  function checkIfWindows_and_balconiesExists() {
    if (checkWindows_and_balconies < new Date().getFullYear() && checkWindows_and_balconies !== "") {
      setWindows_and_balconiesError(true);
    } else {
      setWindows_and_balconiesError(false);
    }
  }
  function checkIfWindows_and_balconiesExists2() {
    if (windows_and_balconiesRenovated > new Date().getFullYear() && windows_and_balconiesRenovated !== "") {
      setWindows_and_balconiesRenovatedError(true);
    } else {
      setWindows_and_balconiesRenovatedError(false);
    }
  }

  function checkIfYardExists() {
    if (checkYard < new Date().getFullYear() && checkYard !== "") {
      setYardError(true);
    } else {
      setYardError(false);
    }
  }
  function checkIfYardExists2() {
    if (yardRenovated > new Date().getFullYear() && yardRenovated !== "") {
      setYardRenovatedError(true);
    } else {
      setYardRenovatedError(false);
    }
  }

  function checkIfYardCoverExists() {
    if (checkYardCover < new Date().getFullYear() && checkYardCover !== "") {
      setYardCoverError(true);
    } else {
      setYardCoverError(false);
    }
  }
  function checkIfYardCoverExists2() {
    if (
      yardCoverRenovated > new Date().getFullYear() &&
      yardCoverRenovated !== ""
    ) {
      setYardCoverRenovatedError(true);
    } else {
      setYardCoverRenovatedError(false);
    }
  }

  function checkIfRoofExists() {
    if (checkRoof < new Date().getFullYear() && checkRoof !== "") {
      setRoofError(true);
    } else {
      setRoofError(false);
    }
  }
  function checkIfRoofExists2() {
    if (roofRenovated > new Date().getFullYear() && roofRenovated !== "") {
      setRoofRenovatedError(true);
    } else {
      setRoofRenovatedError(false);
    }
  }

  function checkIfElevatorExists() {
    if (checkElevator < new Date().getFullYear() && checkElevator !== "") {
      setElevatorError(true);
    } else {
      setElevatorError(false);
    }
  }
  function checkIfElevatorExists2() {
    if (
      elevatorRenovated > new Date().getFullYear() &&
      elevatorRenovated !== ""
    ) {
      setElevatorRenovatedError(true);
    } else {
      setElevatorRenovatedError(false);
    }
  }
  function checkIfConstructedExists() {
    if (
      (constructed < 1850 || constructed > new Date().getFullYear()) &&
      constructed !== ""
    ) {
      setConstructedError(true);
    } else {
      setConstructedError(false);
    }
  }

  function onlyAcceptLetters(event) {
    const inputElement = event.target;
    const inputValue = inputElement.value;
    const inputKey = event.key;
    const selectionStart = inputElement.selectionStart;

    // Block double spaces and spaces at the beginning
    if (
      inputKey === " " &&
      (inputValue.endsWith(" ") || selectionStart === 0)
    ) {
      event.preventDefault();
    }

    // Allow the delete button, arrow keys, and backspace
    if (
      inputKey === "Delete" ||
      inputKey.includes("Arrow") ||
      inputKey === "Backspace"
    ) {
      return;
    }

    // Block any characters that are not letters (uppercase or lowercase)
    if (!/^[a-zA-ZäöÄÖ]$/.test(inputKey)) {
      event.preventDefault();
    }
  }

  function onlyAcceptNumbers(event) {
    const inputElement = event.target;
    const inputValue = inputElement.value;
    const inputKey = event.key;
    const selectionStart = inputElement.selectionStart;

    // Block double spaces and spaces at the beginning
    if (
      inputKey === " " &&
      (inputValue.endsWith(" ") || selectionStart === 0)
    ) {
      event.preventDefault();
    }

    // Allow the delete button and arrow keys
    if (inputKey === "Delete" || inputKey.includes("Arrow")) {
      return;
    }

    // Block any additional "." or "," at the start of the input and only allow numbers (0-9) and the backspace key
    if (
      inputKey === "." ||
      inputKey === "," ||
      (!/[\d]/.test(inputKey) && inputKey !== "Backspace")
    ) {
      event.preventDefault();
    }
  }

  function onlyAcceptNumericValues(event) {
    const inputElement = event.target;
    const inputValue = inputElement.value;
    const inputKey = event.key;
    const selectionStart = inputElement.selectionStart;
    const selectionEnd = inputElement.selectionEnd;

    if (inputKey === "," || inputKey === ".") {
      // Replace "," or "." with "." and prevent multiple periods
      if (!inputValue.includes(".") && inputValue !== "") {
        inputElement.value =
          inputValue.slice(0, selectionStart) +
          "." +
          inputValue.slice(selectionEnd);
        inputElement.setSelectionRange(selectionStart + 1, selectionStart + 1);
        event.preventDefault();
      }
    } else if (inputKey === " ") {
      // Block double spaces and spaces at the beginning
      if (inputValue.endsWith(" ") || selectionStart === 0) {
        event.preventDefault();
      }
    } else if (inputKey === "Delete" || inputKey.includes("Arrow")) {
      // Allow the delete button and arrow keys
      return;
    }

    // Block any additional "." or "," at the start of the input and only allow numbers (0-9) and the backspace key
    if (
      inputKey === "." ||
      inputKey === "," ||
      (!/[\d. ]/.test(inputKey) && inputKey !== "Backspace") ||
      (inputKey === " " && inputValue === "")
    ) {
      event.preventDefault();
    }
  }

  function submitHandler(event) {
    event.preventDefault();

    const enteredname = nameInputRef.current.value;
    const enteredaddress = `${address1}${
      address2 !== "" ? " / " : ""
    }${address2}${address3 !== "" ? " / " : ""}${address3}`;
    const enteredpostNumber = postNumberInputRef.current.value;
    const enteredcity = cityInputRef.current.value;
    const enteredpropertyType = propertyTypeInputRef.current.value;
    const enteredconstructed = constructedInputRef.current.value;
    const enteredfloors = floorsInputRef.current.value;
    const enteredapartments = apartmentsInputRef.current.value;
    const enteredresidentialArea = residentialAreaInputRef.current.value;
    const enteredcommercialArea = commercialAreaInputRef.current.value;
    const enteredapartmentArea = apartmentAreaInputRef.current.value;
    /* const enteredloanPeriod = loanPeriodInputRef.current.value; */
    const enteredrate =
      rateInputRef.current.value === "0" ? "0.1" : rateInputRef.current.value;

    const enteredsewer_area = sewer_areaInputRef.current.value;
    const enteredsewer_renovated = sewer_renovatedInputRef.current.value;
    const enteredsewer_plannedRenovation =
      sewer_plannedRenovationInputRef.current.value;
    const enteredsewer_rPrice = sewer_rPriceInputRef.current.value;
    const enteredsewer_rRPrice = sewer_rRPriceInputRef.current.value;

    const enteredplumbing_area = plumbing_areaInputRef.current.value;
    const enteredplumbing_renovated = plumbing_renovatedInputRef.current.value;
    const enteredplumbing_plannedRenovation =
      plumbing_plannedRenovationInputRef.current.value;
    const enteredplumbing_rPrice = plumbing_rPriceInputRef.current.value;
    const enteredplumbing_rRPrice = plumbing_rPriceInputRef.current.value;

    const enteredbathrooms = bathroomsInputRef.current.value;
    const enteredbathroom_renovated = bathroom_renovatedInputRef.current.value;
    const enteredbathroom_plannedRenovation =
      bathroom_plannedRenovationInputRef.current.value;
    const enteredbathroom_price = bathroom_priceInputRef.current.value;
    const enteredbathroom_rRPrice = bathroom_rRPriceInputRef.current.value;

    const enteredheating_area = heating_areaInputRef.current.value;
    const enteredheating_renovated = heating_renovatedInputRef.current.value;
    const enteredheating_plannedRenovation =
      heating_plannedRenovationInputRef.current.value;
    const enteredheating_rPrice = heating_rPriceInputRef.current.value;
    const enteredheating_rRPrice = heating_rRPriceInputRef.current.value;

    const enteredelec_area = elec_areaInputRef.current.value;
    const enteredelec_renovated = elec_renovatedInputRef.current.value;
    const enteredelec_plannedRenovation =
      elec_plannedRenovationInputRef.current.value;
    const enteredelec_rPrice = elec_rPriceInputRef.current.value;
    const enteredelec_rRPrice = elec_rRPriceInputRef.current.value;

    const enteredvent_area = vent_areaInputRef.current.value;
    const enteredvent_renovated = vent_renovatedInputRef.current.value;
    const enteredvent_plannedRenovation =
      vent_plannedRenovationInputRef.current.value;
    const enteredvent_rPrice = vent_rPriceInputRef.current.value;
    const enteredvent_rRPrice = vent_rRPriceInputRef.current.value;

    const enteredfront_area = front_areaInputRef.current.value;
    const enteredfront_renovated = front_renovatedInputRef.current.value;
    const enteredfront_plannedRenovation =
      front_plannedRenovationInputRef.current.value;
    const enteredfront_rPrice = front_rPriceInputRef.current.value;
    const enteredfront_rRPrice = front_rRPriceInputRef.current.value;

    const enteredwindows_and_balconies_area = windows_and_balconies_areaInputRef.current.value;
    const enteredwindows_and_balconies_renovated = windows_and_balconies_renovatedInputRef.current.value;
    const enteredwindows_and_balconies_plannedRenovation =
      windows_and_balconies_plannedRenovationInputRef.current.value;
    const enteredwindows_and_balconies_rPrice = windows_and_balconies_rPriceInputRef.current.value;
    const enteredwindows_and_balconies_rRPrice = windows_and_balconies_rRPriceInputRef.current.value;

    /* const enteredyard_area = yard_areaInputRef.current.value; */
    const enteredyard_renovated = yard_renovatedInputRef.current.value;
    const enteredyard_plannedRenovation =
      yard_plannedRenovationInputRef.current.value;
    const enteredyard_price = yard_priceInputRef.current.value;
    const enteredyard_rRPrice = yard_rRPriceInputRef.current.value;

    const enteredyard_cover_area = showYardCase
      ? yard_cover_areaInputRef.current.value
      : 0;
    const enteredyard_cover_renovated = showYardCase
      ? yard_cover_renovatedInputRef.current.value
      : 0;
    const enteredyard_cover_plannedRenovation = showYardCase
      ? yard_cover_plannedRenovationInputRef.current.value
      : 0;
    const enteredyard_cover_price = showYardCase
      ? yard_cover_priceInputRef.current.value
      : 0;
    const enteredyard_cover_rRPrice = showYardCase
      ? yard_cover_rRPriceInputRef.current.value
      : 0;

    /* const enteredroof_area = roof_areaInputRef.current.value; */
    const enteredroof_renovated = roof_renovatedInputRef.current.value;
    const enteredroof_plannedRenovation =
      roof_plannedRenovationInputRef.current.value;
    const enteredroof_price = roof_priceInputRef.current.value;
    const enteredroof_rRPrice = roof_rRPriceInputRef.current.value;

    const enteredelevators = elevatorsInputRef.current.value;
    const enteredelevators_renovated =
      elevators_renovatedInputRef.current.value;
    const enteredelevators_plannedRenovation =
      elevators_plannedRenovationInputRef.current.value;
    const enteredelevators_price = elevators_priceInputRef.current.value;
    const enteredelevators_rRPrice = elevators_rRPriceInputRef.current.value;

    const newData = {
      name: enteredname,
      address: enteredaddress,
      postNumber: enteredpostNumber,
      city: enteredcity,
      propertyType: enteredpropertyType,
      constructed: enteredconstructed,
      floors: enteredfloors,
      apartments: enteredapartments,
      residentialArea: enteredresidentialArea,
      commercialArea: enteredcommercialArea,
      apartmentArea: enteredapartmentArea,
      loanPeriod: 20 /* enteredloanPeriod */,
      rate: enteredrate,
      sewer_area: enteredsewer_area,
      sewer_renovated: enteredsewer_renovated,
      sewer_plannedRenovation: enteredsewer_plannedRenovation,
      sewer_rPrice: enteredsewer_rPrice,
      sewer_rRPrice: enteredsewer_rRPrice,

      plumbing_area: enteredplumbing_area,
      plumbing_renovated: enteredplumbing_renovated,
      plumbing_plannedRenovation: enteredplumbing_plannedRenovation,
      plumbing_rPrice: enteredplumbing_rPrice,
      plumbing_rRPrice: enteredplumbing_rRPrice,

      bathrooms: enteredbathrooms,
      bathroom_renovated: enteredbathroom_renovated,
      bathroom_plannedRenovation: enteredbathroom_plannedRenovation,
      bathroom_price: enteredbathroom_price,
      bathroom_rRPrice: enteredbathroom_rRPrice,

      heating_area: enteredheating_area,
      heating_renovated: enteredheating_renovated,
      heating_plannedRenovation: enteredheating_plannedRenovation,
      heating_rPrice: enteredheating_rPrice,
      heating_rRPrice: enteredheating_rRPrice,

      elec_area: enteredelec_area,
      elec_renovated: enteredelec_renovated,
      elec_plannedRenovation: enteredelec_plannedRenovation,
      elec_rPrice: enteredelec_rPrice,
      elec_rRPrice: enteredelec_rRPrice,

      vent_area: enteredvent_area,
      vent_renovated: enteredvent_renovated,
      vent_plannedRenovation: enteredvent_plannedRenovation,
      vent_rPrice: enteredvent_rPrice,
      vent_rRPrice: enteredvent_rRPrice,

      front_area: enteredfront_area,
      front_renovated: enteredfront_renovated,
      front_plannedRenovation: enteredfront_plannedRenovation,
      front_rPrice: enteredfront_rPrice,
      front_rRPrice: enteredfront_rRPrice,

      windows_and_balconies_area: enteredwindows_and_balconies_area,
      windows_and_balconies_renovated: enteredwindows_and_balconies_renovated,
      windows_and_balconies_plannedRenovation: enteredwindows_and_balconies_plannedRenovation,
      windows_and_balconies_rPrice: enteredwindows_and_balconies_rPrice,
      windows_and_balconies_rRPrice: enteredwindows_and_balconies_rRPrice,

      yard_area: Data.yard_area,
      yard_renovated: enteredyard_renovated,
      yard_plannedRenovation: enteredyard_plannedRenovation,
      yard_price: enteredyard_price,
      yard_rRPrice: enteredyard_rRPrice,

      yard_cover_area: enteredyard_cover_area,
      yard_cover_renovated: enteredyard_cover_renovated,
      yard_cover_plannedRenovation: enteredyard_cover_plannedRenovation,
      yard_cover_price: enteredyard_cover_price,
      yard_cover_rRPrice: enteredyard_cover_rRPrice,

      roof_area: Data.roof_area,
      roof_renovated: enteredroof_renovated,
      roof_plannedRenovation: enteredroof_plannedRenovation,
      roof_price: enteredroof_price,
      roof_rRPrice: enteredroof_rRPrice,

      elevators: enteredelevators,
      elevators_renovated: enteredelevators_renovated,
      elevators_plannedRenovation: enteredelevators_plannedRenovation,
      elevators_price: enteredelevators_price,
      elevators_rRPrice: enteredelevators_rRPrice,

      checked: isChecked,
    };
    if (
      sewerError ||
      sewerRenovatedError ||
      plumbingError ||
      plumbingRenovatedError ||
      bathroomError ||
      bathroomRenovatedError ||
      heatingError ||
      heatingRenovatedError ||
      elecError ||
      elecRenovatedError ||
      ventError ||
      ventRenovatedError ||
      frontError ||
      frontRenovatedError ||
      windows_and_balconiesError ||
      windows_and_balconiesRenovatedError ||
      yardError ||
      yardRenovatedError ||
      yardCoverError ||
      yardCoverRenovatedError ||
      roofError ||
      roofRenovatedError ||
      elevatorError ||
      elevatorRenovatedError ||
      constructedError === true
    ) {
    } else {
      props.onChangeOfEstate(newData);
    }
  }
  return (
    <div
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        marginInline: "2.5%",
        marginBottom: "2.5%",
      }}
    >
      <img
        src={returnArrow}
        alt="takaisin"
        style={{
          width: "40px",
          cursor: "pointer",
          marginTop: "2%",
          marginBottom: "1%",
        }}
        onClick={() => window.history.back()}
      ></img>
      <form onSubmit={submitHandler}>
        <table className={classes.local}>
          <thead>
            <tr>
              <th>Taloyhtiön nimi</th>
              <th>
                <section
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <>Osoite</>
                  <select
                    ref={addressNumber}
                    style={{
                      backgroundColor: "transparent",
                      border: "0",
                      width: "20%",
                    }}
                    onChange={() => {
                      setRef();
                      setAddressValue(
                        returnAddress(addressNumber.current.value)
                      );
                    }}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </section>
              </th>
              <th>Postinumero</th>
              <th>Kaupunki</th>
              <th>Kiinteistön tyyppi</th>
              <th /* colSpan={2} */>Rakennusvuosi</th>
            </tr>
            <tr>
              <td>
                <input
                  type="text"
                  name="name"
                  autoComplete="off"
                  defaultValue={Data.name}
                  ref={nameInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="address"
                  autoComplete="off"
                  onChange={() =>
                    setAddress(
                      addressNumber.current.value,
                      addressInputRef.current.value
                    )
                  }
                  defaultValue={addressValue}
                  key={addressValue}
                  ref={addressInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="postNumber"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  defaultValue={Data.postNumber}
                  ref={postNumberInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="city"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptLetters(e)}
                  defaultValue={Data.city}
                  ref={cityInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td>
                <select
                  style={{ border: "none", backgroundColor: "transparent" }}
                  type="number"
                  name="propertyType"
                  autoComplete="off"
                  value={normalValue}
                  onChange={(e) => setNormalValue(e.target.value)}
                  required
                  ref={propertyTypeInputRef}
                >
                  {/* <option disabled selected="selected"></option> */}
                  <option value="0">Kerrostalo</option>
                  <option value="1" disabled>
                    Pientalo
                  </option>
                </select>
              </td>
              <td
                /* colSpan={2}  */ style={{
                  backgroundColor: constructedError ? errorColor : "",
                }}
              >
                <input
                  type="number"
                  name="constructed"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    setConstructed(e.target.value);
                  }}
                  defaultValue={Data.constructed}
                  title={constructedError ? errorText3 : ""}
                  ref={constructedInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
            </tr>
            <tr>
              <th>Kerrosmäärä</th>
              <th>Huoneistojen määrä</th>
              <th>
                Huoneistoala (m<sup>2</sup>)
              </th>
              <th>
                Liikehuoneistojen pinta-ala (m<sup>2</sup>)
              </th>
              <th>
                Tarkasteltavan asunnon pinta-ala (m<sup>2</sup>)
              </th>
              {/* <th>Laina-aika</th> */}
              <th>Korko</th>
            </tr>
            <tr>
              <td>
                <input
                  type="number"
                  name="floors"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onWheel={(e) => e.target.blur()}
                  onChange={(e) => {
                    setPreFloors(e.target.value);
                    reRenderRoof(e.target.value);
                  }}
                  defaultValue={Data.floors}
                  ref={floorsInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td>
                <input
                  type="number"
                  name="apartments"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onWheel={(e) => e.target.blur()}
                  defaultValue={Data.apartments}
                  onChange={(e) => setDynamicBathrooms(e.target.value)}
                  ref={apartmentsInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="residentialArea"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  value={resAreaVal}
                  onChange={(e) => {
                    avoidSpaces("Huoneistojenala", e.target.value);
                    assignValues(preCommercialArea, e.target.value);
                  }}
                  ref={residentialAreaInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="commercialArea"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  value={comAreaVal}
                  onChange={(e) => {
                    avoidSpaces("commercialArea", e.target.value);
                    assignValues(e.target.value, preResidentialArea);
                  }}
                  ref={commercialAreaInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="apartmentArea"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  defaultValue={Data.apartmentArea}
                  ref={apartmentAreaInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              {/* <td><div style={{ display: "flex", border: "none" }}><input
                style={{ backgroundColor: "transparent", border: "none" }}
                type="text"
                autoComplete="off"
                defaultValue={Data.loanperiod}
                id="loanPeriod"
                ref={loanPeriodInputRef}
              /><span>v</span></div></td> */}
              <td>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    style={{ backgroundColor: "transparent", border: "none" }}
                    type="text"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    defaultValue={Data.rate === 0 ? 0.1 : Data.rate}
                    id="rate"
                    ref={rateInputRef}
                  />
                  <span>%</span>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                {(userData.privilege === 0 || userData.privilege === 1) && (
                  <div style={{ display: "flex", justifyItems: "center" }}>
                    Varmistettu
                    <div style={{ width: "5px" }} />
                    <input
                      type="checkBox"
                      style={{ width: "15px" }}
                      checked={isChecked}
                      onChange={() => setIsChecked(!isChecked)}
                    />
                  </div>
                )}
              </th>
              <th>Remontoitava määrä</th>
              <th>Rakennettu / remontoitu viimeksi v</th>
              <th>Suunniteltu korjausvuosi v</th>
              <th>Hinta € / korjattava</th>
              <th colSpan={2}>
                Hinta € / asuin m<sup>2</sup>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Viemäriputket </th>
              <td>
                <input
                  type="text"
                  name="sewer_area"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  defaultValue={dynamicSewer_area}
                  ref={sewer_areaInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td
                style={{
                  backgroundColor: sewerRenovatedError ? errorColor : "",
                }}
              >
                <input
                  type="text"
                  name="sewer_renovated"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setSewerRenovated(e.target.value);
                  }}
                  defaultValue={Data.sewer_renovated}
                  title={sewerRenovatedError ? errorText2 : ""}
                  ref={sewer_renovatedInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td style={{ backgroundColor: sewerError ? errorColor : "" }}>
                <input
                  type="text"
                  name="sewer_plannedRenovation"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setCheckSewer(e.target.value);
                  }}
                  placeholder="Tuo PTS tai anna olettaa"
                  defaultValue={checkIfEdited(Data.sewer_plannedRenovation)}
                  key={checkIfEdited(Data.sewer_plannedRenovation)}
                  title={sewerError ? errorText : ""}
                  ref={sewer_plannedRenovationInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    type="text"
                    name="sewer_rPrice"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    defaultValue={newSewer_rPrice}
                    ref={sewer_rPriceInputRef}
                    style={{ backgroundColor: "transparent", border: "none" }}
                  />
                  <span>
                    m<sup>2</sup>
                  </span>
                </div>
              </td>
              <td colSpan={2}>
                <input
                  type="number"
                  name="sewer_rRPrice"
                  autoComplete="off"
                  disabled
                  value={Math.round(Data.sewer_rRPrice)}
                  ref={sewer_rRPriceInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
            </tr>
            <tr>
              <th>Käyttövesiputket</th>
              <td>
                <input
                  type="text"
                  name="plumbing_area"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  defaultValue={dynamicPlumbing_area}
                  ref={plumbing_areaInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td
                style={{
                  backgroundColor: plumbingRenovatedError ? errorColor : "",
                }}
              >
                <input
                  type="text"
                  name="plumbing_renovated"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setPlumbingRenovated(e.target.value);
                  }}
                  defaultValue={Data.plumbing_renovated}
                  title={plumbingRenovatedError ? errorText2 : ""}
                  ref={plumbing_renovatedInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td style={{ backgroundColor: plumbingError ? errorColor : "" }}>
                <input
                  type="text"
                  name="plumbing_plannedRenovation"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setCheckPlumbing(e.target.value);
                  }}
                  placeholder="Tuo PTS tai anna olettaa"
                  defaultValue={checkIfEdited(Data.plumbing_plannedRenovation)}
                  key={checkIfEdited(Data.plumbing_plannedRenovation)}
                  title={plumbingError ? errorText : ""}
                  ref={plumbing_plannedRenovationInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    type="text"
                    name="plumbing_rPrice"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    defaultValue={newPlumbing_rPrice}
                    ref={plumbing_rPriceInputRef}
                    style={{ backgroundColor: "transparent", border: "none" }}
                  />
                  <span>
                    m<sup>2</sup>
                  </span>
                </div>
              </td>
              <td colSpan={2}>
                <input
                  type="number"
                  name="plumbing_rRPrice"
                  autoComplete="off"
                  disabled
                  value={Math.round(Data.plumbing_rRPrice)}
                  ref={plumbing_rRPriceInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
            </tr>
            <tr>
              <th>Märkätilat</th>
              <td>
                <input
                  type="text"
                  name="bathrooms"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  defaultValue={dynamicBathrooms}
                  ref={bathroomsInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td
                style={{
                  backgroundColor: bathroomRenovatedError ? errorColor : "",
                }}
              >
                <input
                  type="text"
                  name="bathroom_renovated"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setBathroomRenovated(e.target.value);
                  }}
                  defaultValue={Data.bathroom_renovated}
                  title={bathroomRenovatedError ? errorText2 : ""}
                  ref={bathroom_renovatedInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td style={{ backgroundColor: bathroomError ? errorColor : "" }}>
                <input
                  type="text"
                  name="bathroom_plannedRenovation"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setCheckBathroom(e.target.value);
                  }}
                  placeholder="Tuo PTS tai anna olettaa"
                  defaultValue={checkIfEdited(Data.bathroom_plannedRenovation)}
                  key={checkIfEdited(Data.bathroom_plannedRenovation)}
                  title={bathroomError ? errorText : ""}
                  ref={bathroom_plannedRenovationInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    type="text"
                    name="bathroom_price"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    defaultValue={newBathroom_price}
                    ref={bathroom_priceInputRef}
                    style={{ backgroundColor: "transparent", border: "none" }}
                  />
                  <span>kpl</span>
                </div>
              </td>
              <td colSpan={2}>
                <input
                  type="number"
                  name="bathroom_rRPice"
                  autoComplete="off"
                  disabled
                  value={Math.round(Data.bathroom_rRPrice)}
                  ref={bathroom_rRPriceInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
            </tr>
            <tr>
              <th>Lämmitysputkisto</th>
              <td>
                <input
                  type="text"
                  name="heating_area"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  defaultValue={dynamicHeating_area}
                  ref={heating_areaInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td
                style={{
                  backgroundColor: heatingRenovatedError ? errorColor : "",
                }}
              >
                <input
                  type="text"
                  name="heating_renovated"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setHeatingRenovated(e.target.value);
                  }}
                  defaultValue={Data.heating_renovated}
                  title={heatingRenovatedError ? errorText2 : ""}
                  ref={heating_renovatedInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td style={{ backgroundColor: heatingError ? errorColor : "" }}>
                <input
                  type="text"
                  name="heating_plannedRenovation"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setCheckHeating(e.target.value);
                  }}
                  placeholder="Tuo PTS tai anna olettaa"
                  defaultValue={checkIfEdited(Data.heating_plannedRenovation)}
                  key={checkIfEdited(Data.heating_plannedRenovation)}
                  title={heatingError ? errorText : ""}
                  ref={heating_plannedRenovationInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    type="text"
                    name="heating_rPrice"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    defaultValue={newHeating_rPrice}
                    ref={heating_rPriceInputRef}
                    style={{ backgroundColor: "transparent", border: "none" }}
                  />
                  <span>
                    m<sup>2</sup>
                  </span>
                </div>
              </td>
              <td colSpan={2}>
                <input
                  type="number"
                  name="heating_rRPrice"
                  autoComplete="off"
                  disabled
                  value={Math.round(Data.heating_rRPrice)}
                  ref={heating_rRPriceInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
            </tr>
            <tr>
              <th>Sähkö- ja telejärjestelmä</th>
              <td>
                <input
                  type="text"
                  name="elec_area"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  defaultValue={dynamicElec_area}
                  ref={elec_areaInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td
                style={{
                  backgroundColor: elecRenovatedError ? errorColor : "",
                }}
              >
                <input
                  type="text"
                  name="elec_renovated"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setElecRenovated(e.target.value);
                  }}
                  defaultValue={Data.elec_renovated}
                  title={elecRenovatedError ? errorText2 : ""}
                  ref={elec_renovatedInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td style={{ backgroundColor: elecError ? errorColor : "" }}>
                <input
                  type="text"
                  name="elec_plannedRenovation"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setCheckElec(e.target.value);
                  }}
                  placeholder="Tuo PTS tai anna olettaa"
                  defaultValue={checkIfEdited(Data.elec_plannedRenovation)}
                  key={checkIfEdited(Data.elec_plannedRenovation)}
                  title={elecError ? errorText : ""}
                  ref={elec_plannedRenovationInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    type="text"
                    name="elec_rPrice"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    defaultValue={newElec_rPrice}
                    ref={elec_rPriceInputRef}
                    style={{ backgroundColor: "transparent", border: "none" }}
                  />
                  <span>
                    m<sup>2</sup>
                  </span>
                </div>
              </td>
              <td colSpan={2}>
                <input
                  type="number"
                  name="elec_rRPrice"
                  autoComplete="off"
                  disabled
                  value={Math.round(Data.elec_rRPrice)}
                  ref={elec_rRPriceInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
            </tr>
            <tr>
              <th>Ilmanvaihto, hormit</th>
              <td>
                <input
                  type="text"
                  name="vent_area"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  defaultValue={dynamicVent_area}
                  ref={vent_areaInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td
                style={{
                  backgroundColor: ventRenovatedError ? errorColor : "",
                }}
              >
                <input
                  type="text"
                  name="vent_renovated"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setVentRenovated(e.target.value);
                  }}
                  defaultValue={Data.vent_renovated}
                  title={ventRenovatedError ? errorText2 : ""}
                  ref={vent_renovatedInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td style={{ backgroundColor: ventError ? errorColor : "" }}>
                <input
                  type="text"
                  name="vent_plannedRenovation"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setCheckVent(e.target.value);
                  }}
                  placeholder="Tuo PTS tai anna olettaa"
                  defaultValue={checkIfEdited(Data.vent_plannedRenovation)}
                  key={checkIfEdited(Data.vent_plannedRenovation)}
                  title={ventError ? errorText : ""}
                  ref={vent_plannedRenovationInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    type="text"
                    name="vent_rPrice"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    defaultValue={newVent_rPrice}
                    ref={vent_rPriceInputRef}
                    style={{ backgroundColor: "transparent", border: "none" }}
                  />
                  <span>
                    m<sup>2</sup>
                  </span>
                </div>
              </td>
              <td colSpan={2}>
                <input
                  type="number"
                  name="vent_rRPrice"
                  autoComplete="off"
                  disabled
                  value={Math.round(Data.vent_rRPrice)}
                  ref={vent_rRPriceInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
            </tr>
            <tr>
              <th>Julkisivut</th>
              <td>
                <input
                  type="text"
                  name="front_area"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  defaultValue={dynamicFront_area}
                  ref={front_areaInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td
                style={{
                  backgroundColor: frontRenovatedError ? errorColor : "",
                }}
              >
                <input
                  type="text"
                  name="front_renovated"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setFrontRenovated(e.target.value);
                  }}
                  defaultValue={Data.front_renovated}
                  title={frontRenovatedError ? errorText2 : ""}
                  ref={front_renovatedInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td style={{ backgroundColor: frontError ? errorColor : "" }}>
                <input
                  type="text"
                  name="front_plannedRenovation"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setCheckFront(e.target.value);
                  }}
                  placeholder="Tuo PTS tai anna olettaa"
                  defaultValue={checkIfEdited(Data.front_plannedRenovation)}
                  key={checkIfEdited(Data.front_plannedRenovation)}
                  title={frontError ? errorText : ""}
                  ref={front_plannedRenovationInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    type="text"
                    name="front_rPrice"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    defaultValue={newFront_rPrice}
                    ref={front_rPriceInputRef}
                    style={{ backgroundColor: "transparent", border: "none" }}
                  />
                  <span>
                    m<sup>2</sup>
                  </span>
                </div>
              </td>
              <td colSpan={2}>
                <input
                  type="number"
                  name="front_rRPRice"
                  autoComplete="off"
                  disabled
                  value={Math.round(Data.front_rRPrice)}
                  ref={front_rRPriceInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
            </tr>
            <tr>
              <th>Ikkunat ja parvekkeet</th>
              <td>
                <input
                  type="text"
                  name="windows_and_balconies_area"
                  autoComplete="off"
                  disabled
                  hidden
                  onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                  defaultValue={dynamicWindows_and_balconies_area}
                  ref={windows_and_balconies_areaInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td
                style={{
                  backgroundColor: windows_and_balconiesRenovatedError ? errorColor : "",
                }}
              >
                <input
                  type="text"
                  name="windows_and_balconies_renovated"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setWindows_and_balconiesRenovated(e.target.value);
                  }}
                  defaultValue={Data.windows_and_balconies_renovated}
                  title={windows_and_balconiesRenovatedError ? errorText2 : ""}
                  ref={windows_and_balconies_renovatedInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td style={{ backgroundColor: frontError ? errorColor : "" }}>
                <input
                  type="text"
                  name="windows_and_balconies_plannedRenovation"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setCheckWindows_and_balconies(e.target.value);
                  }}
                  placeholder="Tuo PTS tai anna olettaa"
                  defaultValue={checkIfEdited(Data.windows_and_balconies_plannedRenovation)}
                  key={checkIfEdited(Data.windows_and_balconies_plannedRenovation)}
                  title={windows_and_balconiesError ? errorText : ""}
                  ref={windows_and_balconies_plannedRenovationInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    type="text"
                    name="windows_and_balconies_rPrice"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    defaultValue={newWindows_and_balconies_rPrice}
                    ref={windows_and_balconies_rPriceInputRef}
                    style={{ backgroundColor: "transparent", border: "none" }}
                  />
                  <span>
                    m<sup>2</sup>
                  </span>
                </div>
              </td>
              <td colSpan={2}>
                <input
                  type="number"
                  name="windows_and_balconies_rRPRice"
                  autoComplete="off"
                  disabled
                  value={Math.round(Data.windows_and_balconies_rRPrice)}
                  ref={windows_and_balconies_rRPriceInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
            </tr>
            <tr>
              <th>Salaojat ja sokkeli</th>
              <td>
                {/* <input
                  type="number"
                  name="yard_area"
                  autoComplete="off"
                  defaultValue={Data.yard_area}
                  disabled
                  ref={yard_areaInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                /> */}
              </td>
              <td
                style={{
                  backgroundColor: yardRenovatedError ? errorColor : "",
                }}
              >
                <input
                  type="text"
                  name="yard_renovated"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setYardRenovated(e.target.value);
                  }}
                  defaultValue={Data.yard_renovated}
                  title={yardRenovatedError ? errorText2 : ""}
                  ref={yard_renovatedInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td style={{ backgroundColor: yardError ? errorColor : "" }}>
                <input
                  type="text"
                  name="yard_plannedRenovation"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setCheckYard(e.target.value);
                  }}
                  placeholder="Tuo PTS tai anna olettaa"
                  defaultValue={checkIfEdited(Data.yard_plannedRenovation)}
                  key={checkIfEdited(Data.yard_plannedRenovation)}
                  title={yardError ? errorText : ""}
                  ref={yard_plannedRenovationInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    type="text"
                    name="yard_price"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    defaultValue={newYard_price}
                    ref={yard_priceInputRef}
                    style={{ backgroundColor: "transparent", border: "none" }}
                  />
                  <span>projekti</span>
                </div>
              </td>
              <td colSpan={2}>
                <input
                  type="number"
                  name="yard_rRPRice"
                  autoComplete="off"
                  disabled
                  value={Math.round(Data.yard_rRPrice)}
                  ref={yard_rRPriceInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
            </tr>
            <tr>
              <th>
                <div
                  style={{
                    display: "flex",
                    border: "none",
                    fontWeight: showYardCase ? "bold" : "normal",
                  }}
                >
                  <>Pihakansi</>
                  <div style={{ width: "5px" }}></div>
                  <input
                    style={{ width: "15px" }}
                    type="checkbox"
                    checked={showYardCase}
                    onChange={() => setShowYardCase(!showYardCase)}
                  ></input>
                </div>
              </th>
              <td>
                <input
                  type="number"
                  name="yard_cover_area"
                  autoComplete="off"
                  defaultValue={
                    showYardCase
                      ? Number(resAreaVal) + Number(comAreaVal)
                      : Data.yard_cover_area
                  }
                  key={
                    showYardCase
                      ? Number(resAreaVal) + Number(comAreaVal)
                      : Data.yard_cover_area
                  }
                  disabled
                  ref={yard_cover_areaInputRef}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    display: "none",
                  }}
                />
              </td>
              <td
                style={{
                  backgroundColor: yardCoverRenovatedError ? errorColor : "",
                }}
              >
                <input
                  type="text"
                  name="yard_cover_renovated"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  disabled={!showYardCase}
                  onChange={(e) => {
                    setYardCoverRenovated(e.target.value);
                  }}
                  defaultValue={Data.yard_cover_renovated} //changed these two (defaultValue and key from contructed to those)
                  key={Data.yard_cover_renovated}
                  title={yardCoverRenovatedError ? errorText2 : ""}
                  ref={yard_cover_renovatedInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td style={{ backgroundColor: yardCoverError ? errorColor : "" }}>
                <input
                  type="text"
                  name="yard_cover_plannedRenovation"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  disabled={!showYardCase}
                  onChange={(e) => {
                    setCheckYardCover(e.target.value);
                  }}
                  placeholder="Tuo PTS tai anna olettaa"
                  defaultValue={checkIfEdited(
                    Data.yard_cover_plannedRenovation
                  )}
                  key={checkIfEdited(Data.yard_cover_plannedRenovation)}
                  title={yardCoverError ? errorText : ""}
                  ref={yard_cover_plannedRenovationInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    type="text"
                    name="yard_price"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    disabled={!showYardCase}
                    defaultValue={newYard_cover_price}
                    ref={yard_cover_priceInputRef}
                    style={{ backgroundColor: "transparent", border: "none" }}
                  />
                  <span>projekti</span>
                </div>
              </td>
              <td colSpan={2}>
                <input
                  type="number"
                  name="yard_cover_rRPRice"
                  autoComplete="off"
                  disabled
                  value={Math.round(Data.yard_cover_rRPrice)}
                  ref={yard_cover_rRPriceInputRef}
                  style={
                    Math.round(Data.yard_cover_rRPrice) === 0
                      ? {
                          backgroundColor: "transparent",
                          border: "none",
                          display: "none",
                        }
                      : { backgroundColor: "transparent", border: "none" }
                  }
                />
              </td>
            </tr>
            <tr>
              <th>Katto</th>
              <td>
                {true &&
                  Math.round(
                    ((Number(preResidentialArea) + Number(preCommercialArea)) /
                      preFloors) *
                      1.2
                  )}
              </td>
              <td
                style={{
                  backgroundColor: roofRenovatedError ? errorColor : "",
                }}
              >
                <input
                  type="text"
                  name="roof_renovated"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setRoofRenovated(e.target.value);
                  }}
                  defaultValue={Data.roof_renovated}
                  title={roofRenovatedError ? errorText2 : ""}
                  ref={roof_renovatedInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td style={{ backgroundColor: roofError ? errorColor : "" }}>
                <input
                  type="text"
                  name="roof_plannedRenovation"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setCheckRoof(e.target.value);
                  }}
                  placeholder="Tuo PTS tai anna olettaa"
                  defaultValue={checkIfEdited(Data.roof_plannedRenovation)}
                  key={checkIfEdited(Data.roof_plannedRenovation)}
                  title={roofError ? errorText : ""}
                  ref={roof_plannedRenovationInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    type="text"
                    name="roof_price"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    defaultValue={dynamicRoof_price}
                    ref={roof_priceInputRef}
                    style={{ backgroundColor: "transparent", border: "none" }}
                  />
                  <span>projekti</span>
                </div>
              </td>
              <td colSpan={2}>
                <input
                  type="number"
                  name="roof_rRPrice"
                  autoComplete="off"
                  disabled
                  value={Math.round(Data.roof_rRPrice)}
                  ref={roof_rRPriceInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
            </tr>
            <tr>
              <th>Hissi</th>
              <td>
                <input
                  type="text"
                  name="elevators"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  defaultValue={Data.elevators}
                  ref={elevatorsInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td
                style={{
                  backgroundColor: elevatorRenovatedError ? errorColor : "",
                }}
              >
                <input
                  type="text"
                  name="elevators_renovated"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setElevatorRenovated(e.target.value);
                  }}
                  defaultValue={Data.elevators_renovated}
                  title={elevatorRenovatedError ? errorText2 : ""}
                  ref={elevators_renovatedInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td style={{ backgroundColor: elevatorError ? errorColor : "" }}>
                <input
                  type="text"
                  name="elevators_plannedRenovation"
                  autoComplete="off"
                  onKeyDownCapture={(e) => onlyAcceptNumbers(e)}
                  onChange={(e) => {
                    setCheckElevator(e.target.value);
                  }}
                  placeholder="Tuo PTS tai anna olettaa"
                  defaultValue={checkIfEdited(Data.elevators_plannedRenovation)}
                  key={checkIfEdited(Data.elevators_plannedRenovation)}
                  title={elevatorError ? errorText : ""}
                  ref={elevators_plannedRenovationInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
              <td>
                <div style={{ display: "flex", border: "none" }}>
                  <input
                    type="text"
                    name="elevators_price"
                    autoComplete="off"
                    onKeyDownCapture={(e) => onlyAcceptNumericValues(e)}
                    defaultValue={newElevators_price}
                    ref={elevators_priceInputRef}
                    style={{ backgroundColor: "transparent", border: "none" }}
                  />
                  <span>kpl</span>
                </div>
              </td>
              <td colSpan={2}>
                <input
                  type="number"
                  name="elevators_rRPrice"
                  autoComplete="off"
                  disabled
                  value={Math.round(Data.elevators_rRPrice)}
                  ref={elevators_rRPriceInputRef}
                  style={{ backgroundColor: "transparent", border: "none" }}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <button className={classes.actionsbutton}>Tallenna muutokset</button>
      </form>
    </div>
  );
}
export default EditPage;
